import http from "./http";
import Promisable from "./Promisable";
import { AppDispatch } from "redux/store";
import { environment } from "environment";
import { authActions } from "../redux/slices/auth/authSlice";

const url = environment.API_END_URL + "/auth";

const AuthService = {
  login: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(authActions.setLoading(true));

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/login`, data)
    );
    if (success) {
      dispatch?.(authActions.setUser(success.data.data.user));
      localStorage.setItem("token", `Bearer ${success.data.data.token}`);
      localStorage.setItem("user", JSON.stringify(success.data.data.user));
    }

    dispatch?.(authActions.setLoading(false));
    return error;
  },

  logout: async (dispatch: AppDispatch) => {
    dispatch?.(authActions.setUser(""));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  },

  getToken: () => {
    const token = localStorage.getItem("token");
    if (token) {
      return { Authorization: token };
    } else {
      return null;
    }
  },

  getUser: () => {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  },
  sendActivity: () => {
    http.setJWT();
    return http.get("activity/set").then((response) => {
      return response.data;
    });
  },
};

export default AuthService;
