import { AppDispatch } from "redux/store";
import { environment } from "environment";
import http from "./http";
import Promisable from "./Promisable";
import { jobsActions } from "redux/slices/job/jobSlice";
import { modalActions } from "redux/slices/modal/modalReducer";

const url = environment.API_END_URL + "/admin";

const JobService = {
  addJob: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(jobsActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/addJob`, data)
    );
    if (success) {
      dispatch?.(jobsActions.addJob(success.data.data.job));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(jobsActions.setLoading(false));
    return error;
  },

  updateJob: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(jobsActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/updateJob`, data)
    );
    if (success) {
      dispatch?.(jobsActions.updateJob(success.data.data.job));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(jobsActions.setLoading(false));
    return error;
  },

  jobStatus: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(jobsActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/jobStatus`, data)
    );
    if (success) {
      dispatch?.(jobsActions.jobStatus(success.data.data.job));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(jobsActions.setLoading(false));
    return error;
  },

  getJobs: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(jobsActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.get(`${url}/getJobs?pagination=${data.pagination}&page=${data.page}`)
    );
    if (success) {
      dispatch?.(jobsActions.getJobs(success.data.data));
    }
    dispatch?.(jobsActions.setLoading(false));
    return error;
  },
};

export default JobService;
