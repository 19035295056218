import { fieldsLeft, fieldsRight } from ".";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import Button from "components/atoms/Button";
import { reduxForm } from "redux-form";
import { Cell, Grid } from "baseui/layout-grid";

const JobForm = ({ handleSubmit }: any) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid gridMargins={[0, 0, 0]}>
          <Cell span={[4, 8, 6]}>
            <ReduxFormFields fields={fieldsLeft} />
          </Cell>
          <Cell span={[4, 8, 6]}>
            <ReduxFormFields fields={fieldsRight} />
          </Cell>
        </Grid>
        <input type="hidden" name="country" />
        <input type="hidden" name="city" />
        <Button type="submit" size="compact">
          Submit
        </Button>
        <br />
      </form>
    </>
  );
};

export default reduxForm({ form: "JobForm" })(JobForm);
