import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import {
  StyledRoot,
  StyledTable,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableHead,
  StyledTableBody,
} from "baseui/table-semantic";
import Button from "components/atoms/Button";
import TableLoader from "components/atoms/TableLoader";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal/modalReducer";
import { ADD_JOB, JOB_STATUS } from "redux/slices/modal/modalType";
import "./Jobs.css";
import Pagination from "./Pagination";

const Jobs = () => {
  const { jobs, loading } = useAppSelector((state) => state.jobs);

  const dispatch = useDispatch();

  return (
    <div className="dashboard">
      <Grid align={ALIGNMENT.center}>
        <Cell span={[4, 4, 6]}>
          <h2>Jobs</h2>
        </Cell>
        <Cell span={[4, 4, 6]}>
          <div style={{ textAlign: "right" }}>
            <Button
              size="compact"
              onClick={() =>
                dispatch(
                  modalActions.openModalWithComponent({
                    type: ADD_JOB,
                    size: "auto",
                  })
                )
              }
            >
              Add Job
            </Button>
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 4, 12]}>
          <StyledRoot style={{ backgroundColor: "transparent" }}>
            <StyledTable style={{ borderSpacing: "0 12px" }}>
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Job title</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Location</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Date</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Rate of pay</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Rank</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell
                    style={{ borderBottomWidth: 0, textAlign: "center" }}
                  >
                    <b>Status</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell
                    style={{ borderBottomWidth: 0, textAlign: "center" }}
                  ></StyledTableHeadCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {!loading &&
                  jobs?.map((row: any, index: number) => (
                    <StyledTableBodyRow
                      key={index}
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.job_title}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.location}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {moment(row.createdAt).format("DD.MM.YYYY")}
                      </StyledTableBodyCell>

                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.rate_of_pay}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.rank}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className={`badge ${
                            row.status === "active" ? "success" : "disabled"
                          }`}
                          onClick={() =>
                            dispatch(
                              modalActions.openModalWithComponent({
                                type: JOB_STATUS,
                                size: "auto",
                                data: {
                                  id: row._id,
                                  status:
                                    row.status === "active"
                                      ? "inactive"
                                      : "active",
                                },
                              })
                            )
                          }
                        >
                          {row.status}
                        </span>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className="link"
                          onClick={() =>
                            dispatch(
                              modalActions.openModalWithComponent({
                                type: ADD_JOB,
                                size: "auto",
                                data: { id: row._id, index: index },
                              })
                            )
                          }
                        >
                          <b>Edit</b>
                        </span>
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
                {loading &&
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <StyledTableBodyRow key={index}>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </Cell>
        <Cell skip={[0, 0, 6]} span={[4, 4, 6]}>
          <Pagination />
        </Cell>
      </Grid>
    </div>
  );
};

export default Jobs;
