import { AppDispatch } from "redux/store";
import { environment } from "environment";
import http from "./http";
import Promisable from "./Promisable";
import { modalActions } from "redux/slices/modal/modalReducer";
import { partnerActions } from 'redux/slices/partner/partnerSlice';

const url = environment.API_END_URL + "/admin";
const PartnerService = {
  getPartners: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(partnerActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.get(
        `${url}/getPartners?pagination=${data.pagination}&page=${data.page}`
      )
    );
    if (success) {
      console.log("Succcess", success);
      dispatch?.(partnerActions.getPartners(success.data.data));
    }
    dispatch?.(partnerActions.setLoading(false));
    return error;
  },

  addRemarks: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(partnerActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/partnerRemarks`, data)
    );
    if (success) {
      dispatch?.(partnerActions.addRemarks(success.data.data.partner));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(partnerActions.setLoading(false));
    return error;
  },

  partnerStatus: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(partnerActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/partnerStatus`, data)
    );
    if (success) {
      dispatch?.(partnerActions.partnerStatus(success.data.data.partner));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(partnerActions.setLoading(false));
    return error;
  },
};

export default PartnerService;
