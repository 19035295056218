import { FocusOnce, ModalBody, ModalHeader } from "baseui/modal";
import JobForm from "./JobForm";
import JobService from "services/jobs.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import UpdateForm from "./JobForm/UpdateForm";

export default function AddJob() {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.modal);
  const loading = useAppSelector((state) => state.jobs.loading);
  const all_values = useAppSelector((state) => state.form.JobForm);

  const handleSubmit = async (values: any) => {
    console.log("Values", values.description);
    console.log("description", all_values?.values?.description);

    if (data?.id) {
      let payload = {
        id: data.id,
        description: JSON.stringify(values.description),
        ...values,
      };
      JobService.updateJob(payload, dispatch);
    } else JobService.addJob(values, dispatch);
  };
  return (
    <>
      <FocusOnce>
        <ModalHeader>Add Job</ModalHeader>
      </FocusOnce>
      <ModalBody>
        {loading && <CircleLoader />}
        <JobForm onSubmit={handleSubmit} />
        {data?.id && <UpdateForm index={data?.index} />}
      </ModalBody>
    </>
  );
}
