import "./App.css";
import "aos/dist/aos.css";

import AOS from "aos";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { BaseProvider } from "baseui";
// import { Unstable_A11y as A11y } from "baseui/a11y";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import LightTheme from "components/atoms/Theme/LigthTheme";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import Header from "components/templates/Header";
import useEffectOnce from "hooks/useEffectOnce";
import AppModal from "components/atoms/Modal/AppModal";

const engine = new Styletron();

export default function App() {
  useEffectOnce(() => {
    AOS.init({ once: true });
  });
  return (
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          {/* <A11y> */}
            <BrowserRouter>
              <Toaster />
              <AppModal />
              <Header />
              <AppRoutes />
            </BrowserRouter>
          {/* </A11y> */}
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  );
}
