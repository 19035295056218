import Textarea from "components/atoms/Textarea";

export default function ReduxTextarea({
  input,
  label,
  children,
  meta: { error, touched },
  ...rest
}: any) {
  return (
    <Textarea
      error={touched && error}
      autoComplete="current-password"
      {...rest}
      {...input}
    />
  );
}
