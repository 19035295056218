import { createTheme } from "baseui";

const primitives = {
  accent: "#A633DB", // hot pink
  accent50: "#F16B2F",
  accent100: "#FFFFFF",

  primaryFontFamily: "'Eina01', sans-serif",
};

const overrides = {
  colors: {
    buttonSecondaryFill: primitives.accent,
    buttonSecondaryText: primitives.accent100,
    buttonSecondaryHover: primitives.accent50,
    buttonSecondaryActive: primitives.accent50,
    typography: {
      DisplayLarge: {
        fontFamily: "'Eina02', sans-serif",
      },
      DisplayMedium: {
        fontFamily: "'Eina02', sans-serif",
      },
      DisplaySmall: {
        fontFamily: "'Eina02', sans-serif",
      },
      DisplayXSmall: {
        fontFamily: "'Eina02', sans-serif",
      },
    },
  },
};

const LightTheme = createTheme(primitives, overrides);

export default LightTheme;
