import { FocusOnce, ModalBody, ModalHeader } from "baseui/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import RemarksForm from "./RemarksForm";
import ContactService from "services/contact.service";
import UpdateForm from "./RemarksForm/UpdateForm";

export default function ContactRemarks() {
  const dispatch = useAppDispatch();
  const { id, index } = useAppSelector((state) => state.modal.data);
  const { loading } = useAppSelector((state) => state.careers);

  const handleSubmit = async (values: any) => {
    let payload = {
      remarks: values.remarks,
      id: id,
    };
    ContactService.addRemarks(payload, dispatch);
  };
  return (
    <>
      <FocusOnce>
        <ModalHeader>Add Remarks</ModalHeader>
      </FocusOnce>
      <ModalBody>
        {loading && <CircleLoader />}
        <RemarksForm onSubmit={handleSubmit} />
        {id && <UpdateForm index={index} />}
      </ModalBody>
    </>
  );
}
