import { AppDispatch } from "redux/store";
import { environment } from "environment";
import http from "./http";
import Promisable from "./Promisable";
import { contactActions } from "redux/slices/contact/contactSlice";
import { modalActions } from "redux/slices/modal/modalReducer";

const url = environment.API_END_URL + "/admin";
const ContactService = {
  getContacts: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(contactActions.setLoading(true));

    http.setJWT();
    console.log("Hit start");
    
    const { error, success }: any = await Promisable.asPromise(
      http.get(
        `${url}/getContacts?pagination=${data.pagination}&page=${data.page}`
      )
    );
    if (success) {
      console.log("Succcess", success)
      dispatch?.(contactActions.getContacts(success.data.data));
    }
    dispatch?.(contactActions.setLoading(false));
    return error;
  },

  addRemarks: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(contactActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/addContactRemarks`, data)
    );
    if (success) {
      dispatch?.(contactActions.addRemarks(success.data.data.contact));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(contactActions.setLoading(false));
    return error;
  },

  contactStatus: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(contactActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/contactStatus`, data)
    );
    if (success) {
      dispatch?.(contactActions.contactStatus(success.data.data.contact));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(contactActions.setLoading(false));
    return error;
  },

};

export default ContactService;
