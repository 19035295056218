import { AppDispatch } from "redux/store";
import moment from "moment";
import authService from "./auth.service";
import { environment } from "../environment";
import ToasterService from "./toaster.service";

let Session = class SessionService {
  events: string[];
  warnTimeout: NodeJS.Timeout | null;
  logoutTimeout: NodeJS.Timeout | null;
  dispatch: AppDispatch;
  constructor(dispatch: AppDispatch) {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.warnTimeout = null;
    this.logoutTimeout = null;
    this.dispatch = dispatch;

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimeout);
    });
    this.init();
  }

  clearTimeout1() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  init() {
    let time = localStorage.getItem("time");

    if (!time) {
      let date = new Date().toISOString();
      localStorage.setItem("time", date);
    }

    this.warnTimeout = setTimeout(
      this.warn,
      (environment.Inactivity_time - 60) * 1000
    );

    this.logoutTimeout = setTimeout(
      this.logout,
      environment.Inactivity_time * 1000
    );
  }

  resetTimeout() {
    let time = localStorage.getItem("time");

    if (time == null) {
      this.destroy();
      return;
    }
    if (time) {
      var start = moment(time);
      var end = moment(new Date().toISOString());
      let minutes = end.diff(start, "seconds");

      if (minutes > environment.Inactivity_time) {
        this.destroy();
      } else {
        localStorage.setItem("time", new Date().toISOString());
        this.clearTimeout1();
        this.init();
        this.sendActivity();
      }
    }
  }

  static setLatestServerCall() {
    localStorage.setItem("sTime", new Date().toISOString());
  }
  warn() {
    ToasterService.showError(`You'll be logout soon due to inactivity`);
  }
  sendActivity() {
    let time = localStorage.getItem("sTime");
    var start = moment(time);
    var end = moment(new Date().toISOString());
    let minutes = end.diff(start, "seconds");
    if (environment.Inactivity_time - minutes <= 30) {
      console.log("api hit");
      console.log(minutes);
      console.log(environment.Inactivity_time - minutes);
      localStorage.setItem("sTime", new Date().toISOString());
      authService.sendActivity();
    }
  }
  logout() {
    console.log("Sending a logout request to the API...");
    this.destroy();
    authService.logout(this.dispatch);
    setTimeout(() => {
      window.location.assign("/");
    }, 300);
  }

  destroy() {
    this.clearTimeout1();
    localStorage.removeItem("time");
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimeout);
    });
  }
};

export default Session;
