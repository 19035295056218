// import { required } from "services/validate";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import FormControlTextareaRedux from "components/organisms/FormControlTextareaRedux";

export { default } from "./RemarksForm";

export const fields: ReduxFormField[] = [
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
    // validate: [required],
    component: FormControlTextareaRedux,
  },
];
