import { Cell, Grid } from "baseui/layout-grid";
import {
  StyledRoot,
  StyledTable,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableHead,
  StyledTableBody,
} from "baseui/table-semantic";
import TableLoader from "components/atoms/TableLoader";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal/modalReducer";
import { PARTNER_REMARKS, PARTNER_STATUS } from "redux/slices/modal/modalType";
import Pagination from "./Pagination";
import "./Partner.css";

const Partner = () => {
  const { partners, loading } = useAppSelector((state) => state.partners);

  const dispatch = useDispatch();

  return (
    <div className="dashboard">
      <Grid>
        <Cell span={[4, 4, 12]}>
          <h2>Partners</h2>
        </Cell>
        <Cell span={[4, 4, 12]}>
          <StyledRoot style={{ backgroundColor: "transparent" }}>
            <StyledTable style={{ borderSpacing: "0 12px" }}>
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Info</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Contact</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Comments</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell
                    style={{ borderBottomWidth: 0, textAlign: "center" }}
                  >
                    <b>Status</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell
                    style={{ borderBottomWidth: 0, textAlign: "center" }}
                  >
                    <b>Remarks</b>
                  </StyledTableHeadCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {!loading &&
                  partners?.map((row: any, index: number) => (
                    <StyledTableBodyRow
                      key={index}
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        <b>{row.company_name}</b> {row.company_name && <br />}
                        {row.name} <br />
                        {moment(row.createdAt).format("DD.MM.YYYY")}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.email} <br />
                        {row.phone} <br />
                        {row.location}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.comments}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                          textAlign: "center",
                        }}
                      >
                        <span
                          className={`badge ${
                            row.status === "approved" ? "success" : "pending"
                          }`}
                          onClick={() =>
                            dispatch(
                              modalActions.openModalWithComponent({
                                type: PARTNER_STATUS,
                                size: "auto",
                                data: {
                                  id: row._id,
                                  status:
                                    row.status === "pending"
                                      ? "approved"
                                      : "pending",
                                },
                              })
                            )
                          }
                        >
                          {row.status}
                        </span>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                          textAlign: "center",
                        }}
                      >
                        <div
                          className={`remark-link ${!row.remarks && "link"}`}
                          onClick={() => {
                            dispatch(
                              modalActions.openModalWithComponent({
                                type: PARTNER_REMARKS,
                                size: "default",
                                data: { id: row._id, index: index },
                              })
                            );
                          }}
                        >
                          {row.remarks ? row.remarks : `Add\bRemarks`}
                        </div>
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
                {loading &&
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <StyledTableBodyRow key={index}>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </Cell>
        <Cell skip={[0, 0, 6]} span={[4, 4, 6]}>
          <Pagination />
        </Cell>
      </Grid>
    </div>
  );
};

export default Partner;
