import { Pagination as BasePagination, SIZE } from "baseui/pagination";
import { environment } from "environment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import JobService from "services/jobs.service";

const Pagination = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { total } = useAppSelector((state) => state.jobs);

  console.log("Jobs", total);

  useEffect(() => {
    let data = {
      pagination: environment.PAGE_SIZE,
      page: currentPage,
    };
    JobService.getJobs(data, dispatch);
  }, [currentPage, dispatch]);
  return (
    <BasePagination
      numPages={total}
      size={SIZE.mini}
      currentPage={currentPage}
      onPageChange={({ nextPage }) => {
        setCurrentPage(Math.min(Math.max(nextPage, 1), total));
      }}
      overrides={{
        Root: {
          style: {
            justifyContent: "end",
          },
        },
      }}
    />
  );
};

export default Pagination;
