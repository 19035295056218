import {
  FocusOnce,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import CircleLoader from "components/atoms/CircleLoader";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal/modalReducer";
import Button from "components/atoms/Button";
import CareerService from "services/careers.service";

export default function ApplicantStatus() {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.modal);
  const { loading } = useAppSelector((state) => state.careers);

  const handleClick = async () => {
    let payload = {
      id: data,
      status: "shortlisted",
    };
    CareerService.careerStatus(payload, dispatch);
  };
  return (
    <div>
      <FocusOnce>
        <ModalHeader>Applicant Status</ModalHeader>
      </FocusOnce>
      <ModalBody>
        {loading && <CircleLoader />}
        Are you sure you want make this applicant status as Shortlisted?
      </ModalBody>

      <ModalFooter>
        <ModalButton
          size="compact"
          kind="tertiary"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          No
        </ModalButton>
        <Button size="compact" onClick={handleClick}>
          Yes
        </Button>
      </ModalFooter>
    </div>
  );
}
