import { Button as BaseButton } from "baseui/button";

type ButtonProps = React.ComponentProps<typeof BaseButton>;

export default function Button(props: ButtonProps) {
  return (
    <BaseButton
      overrides={{
        BaseButton: {
          style: ({ $theme }) => {
            return {
              backgroundColor: $theme.colors.accent,
              color: $theme.colors.accent100,
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              ":hover": {
                backgroundColor: $theme.colors.accent50,
              },
            };
          },
        },
      }}
      {...props}
    />
  );
}
