import useAuth from "hooks/useAuth";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  roles?: string[];
  children: React.ReactNode;
}

export default function PrivateRoute({
  roles = [],
  children,
}: PrivateRouteProps) {
  const auth = useAuth();

  if (auth.token === null) return <Navigate to="/" />;
  
  if (roles.length > 0 && !roles.includes(auth?.user?.role))
    return <Navigate to="/" />;

  return <>{children}</>;
}
