import {
  FocusOnce,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import CircleLoader from "components/atoms/CircleLoader";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import JobService from "services/jobs.service";
import { modalActions } from "redux/slices/modal/modalReducer";
import Button from "components/atoms/Button";

export default function JobStatus() {
  const dispatch = useAppDispatch();
  const { id, status } = useAppSelector((state) => state.modal.data);
  const { loading } = useAppSelector((state) => state.careers);

  const handleClick = async (values: any) => {
    let payload = {
      id: id,
      status: status,
    };
    JobService.jobStatus(payload, dispatch);
  };
  return (
    <div>
      <FocusOnce>
        <ModalHeader>Job Status</ModalHeader>
      </FocusOnce>
      <ModalBody>
        {loading && <CircleLoader />}
        Are you sure you want make this job status as {status}?
      </ModalBody>

      <ModalFooter>
        <ModalButton
          size="compact"
          kind="tertiary"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          No
        </ModalButton>
        <Button size="compact" onClick={handleClick}>
          Yes
        </Button>
      </ModalFooter>
    </div>
  );
}
