import { Textarea as BaseTextarea } from "baseui/textarea";

export default function Textarea(props: any) {
  return (
    <BaseTextarea
      overrides={{
        Root: {
          style: (prop: any) => {
            const {
              $disabled,
              $error,
              $isFocused,
              $theme: { colors },
            } = prop;
            const border = $disabled
              ? colors.borderAlt
              : $error
              ? colors.borderError
              : $isFocused
              ? "#a633db"
              : "#cdcdcd";
            return {
              borderLeftColor: border,
              borderRightColor: border,
              borderTopColor: border,
              borderBottomColor: border,
              borderTopWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              backgroundColor: "#ffffff",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            };
          },
        },
        InputContainer: {
          style: {
            backgroundColor: "#ffffff",
          },
        },
      }}
      rows="6"
      {...props}
    />
  );
}
