import { AppDispatch } from "redux/store";
import { environment } from "environment";
import http from "./http";
import Promisable from "./Promisable";
import { careersActions } from "redux/slices/career/careerSlice";
import { modalActions } from "redux/slices/modal/modalReducer";

const url = environment.API_END_URL + "/admin";
const CareerService = {
  getCareers: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(careersActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.get(`${url}/getCareers/?id=${data}`)
    );
    if (success) {
      dispatch?.(careersActions.getCareers(success.data.data.careers));
    }
    dispatch?.(careersActions.setLoading(false));
    return error;
  },

  addRemarks: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(careersActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/addRemarks`, data)
    );
    if (success) {
      dispatch?.(careersActions.addRemarks(success.data.data.career));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(careersActions.setLoading(false));
    return error;
  },

  careerStatus: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(careersActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.post(`${url}/careerStatus`, data)
    );
    if (success) {
      dispatch?.(careersActions.careerStatus(success.data.data.career));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(careersActions.setLoading(false));
    return error;
  },
};

export default CareerService;
