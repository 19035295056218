export default class Promisable {
  static asPromise(promise: Promise<unknown>) {
    let response = { error: null, success: null };

    return new Promise((resolve) => {
      promise.then(
        (res: any) => {
          response.success = res;
          resolve(response);
        },
        (err: any) => {
          response.error = err;
          resolve(response);
        }
      );
    });
  }
}
