import { PartnerState } from "./partnerType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PartnerState = {
  partners: [],
  total: 0,
  loading: false,
};

export const partnerSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    getPartners: (state, action) => {
      state.loading = false;
      state.total = action.payload.total;
      state.partners = action.payload.partners;
    },
    addRemarks: (state, action) => {
      state.loading = false;
      let index = state.partners.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.partners[index] = action.payload;
    },
    partnerStatus: (state, action) => {
      state.loading = false;
      let index = state.partners.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.partners[index] = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
const partnersReducer = partnerSlice.reducer;

export const partnerActions = partnerSlice.actions;
export default partnersReducer;
