import { ContactState } from "./contactType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ContactState = {
  contacts: [],
  total: 0,
  loading: false,
};

export const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    getContacts: (state, action) => {
      state.loading = false;
      state.total = action.payload.total;
      state.contacts = action.payload.contacts;
    },
    addRemarks: (state, action) => {
      state.loading = false;
      let index = state.contacts.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.contacts[index] = action.payload;
    },
    contactStatus: (state, action) => {
      state.loading = false;
      let index = state.contacts.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.contacts[index] = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
const contactsReducer = contactSlice.reducer;

export const contactActions = contactSlice.actions;
export default contactsReducer;
