import AuthService from "services/auth.service";

export default function useAuth() {
  let user = AuthService.getUser();
  let token = AuthService.getToken();
  let auth = {
    user: user,
    token: token,
  };
  return auth;
}
