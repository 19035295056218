import { JobState } from "./jobType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: JobState = {
  jobs: [],
  total: 0,
  loading: false,
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    getJobs: (state, action) => {
      console.log("Actions", action.payload);
      state.loading = false;
      state.total = action.payload.total;
      state.jobs = action.payload.jobs;
    },
    addJob: (state, action) => {
      state.loading = false;
      state.jobs.unshift(action.payload);
    },
    updateJob: (state, action) => {
      state.loading = false;
      let index = state.jobs.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.jobs[index] = action.payload;
    },
    jobStatus: (state, action) => {
      state.loading = false;
      let index = state.jobs.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.jobs[index] = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const jobsReducer = jobSlice.reducer;

export const jobsActions = jobSlice.actions;
export default jobsReducer;
