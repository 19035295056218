import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthService from "services/auth.service";
import Button from "components/atoms/Button";
import { useLocation, useNavigate } from "react-router";
import "./Header.css";
import { Cell, Grid } from "baseui/layout-grid";
import { useEffect } from "react";
import Session from "services/session.service";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const { pathname } = location;

  const logOut = () => {
    AuthService.logout(dispatch);
    navigate("/");
  };

  useEffect(() => {
    let session: any;

    if (pathname !== "/") session = new Session(dispatch);

    return () => {
      if (pathname !== "/") session.destroy();
    };
  }, [dispatch, pathname]);

  if (pathname === "/") {
    return <></>;
  } else {
    return (
      <Grid>
        <Cell span={[4, 8, 12]}>
          <div className="header">
            <nav className="navbar">
              <div className="navbar-brand">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              <ul className="nav-list">
                <li className="nav-item">
                  <Link
                    to="/careers"
                    className={`${pathname === "/careers" && "active"}`}
                  >
                    Careers{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/jobs"
                    className={`${pathname === "/jobs" && "active"}`}
                  >
                    Jobs{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/partners"
                    className={`${pathname === "/partners" && "active"}`}
                  >
                    Partners{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contacts"
                    className={`${pathname === "/contacts" && "active"}`}
                  >
                    Contacts{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/subscribers"
                    className={`${pathname === "/subscribers" && "active"}`}
                  >
                    Subscribers{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Button size="compact" onClick={logOut}>
                    Logout
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </Cell>
      </Grid>
    );
  }
}
