import { required } from "services/validate";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import FormControlInputRedux from "components/organisms/FormControlInputRedux";
import Autocomplete from "components/molecules/Autocomplete";
import TextEditor from "components/atoms/TextEditor";

export { default } from "./JobForm";

export const fieldsLeft: ReduxFormField[] = [
  {
    name: "job_title",
    label: "Job Title",
    type: "text",
    validate: [required],
    component: FormControlInputRedux,
  },
  {
    name: "location",
    label: "Location",
    validate: [required],
    component: Autocomplete,
  },
  {
    name: "rate_of_pay",
    label: "Rate of pay",
    type: "text",
    validate: [required],
    component: FormControlInputRedux,
  },
  {
    name: "rank",
    label: "Rank",
    type: "text",
    validate: [required],
    component: FormControlInputRedux,
  },
];

export const fieldsRight: ReduxFormField[] = [
  {
    name: "description",
    label: "Description",
    component: TextEditor,
    validate: [required],
  },
];
