import { fields } from ".";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";

const RemarksForm = ({ handleSubmit }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <ReduxFormFields fields={fields} />
          <Button type="submit" size="compact">Submit</Button>
    </form>
  );
};

export default reduxForm({ form: "RemarksForm" })(RemarksForm);
