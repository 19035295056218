import { Modal, SIZE } from "baseui/modal";
import Remarks from "pages/Careers/Remarks";
import AddJob from "pages/Jobs/AddJob";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal/modalReducer";
import ContactRemarks from "pages/Contact/Remarks/ContactRemarks";
import {
  ADD_CONTACT_REMARKS,
  ADD_JOB,
  ADD_REMARKS,
  CAREER_STATUS,
  CONTACT_STATUS,
  JOB_STATUS,
  PARTNER_REMARKS,
  PARTNER_STATUS,
} from "redux/slices/modal/modalType";
import JobStatus from "pages/Jobs/JobStatus";
import ApplicantStatus from "pages/Careers/Applicants/ApplicantStatus";
import ContactStatus from "pages/Contact/ContactStatus";
import PartnerStatus from "pages/Partner/PartnerStatus";
import PartnerRemarks from "pages/Partner/Remarks/PartnerRemarks";

function AppModal() {
  const dispatch = useAppDispatch();
  const { type, isOpen, size } = useAppSelector((state) => state.modal);
  return (
    <Modal
      isOpen={isOpen}
      size={size === "default" ? SIZE.default : SIZE.auto}
      unstable_ModalBackdropScroll={true}
      onClose={() => dispatch(modalActions.closeModal())}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      {type === ADD_JOB && <AddJob />}
      {type === ADD_REMARKS && <Remarks />}
      {type === JOB_STATUS && <JobStatus />}
      {type === CONTACT_STATUS && <ContactStatus />}
      {type === CAREER_STATUS && <ApplicantStatus />}
      {type === ADD_CONTACT_REMARKS && <ContactRemarks />}
      {type === PARTNER_STATUS && <PartnerStatus />}
      {type === PARTNER_REMARKS && <PartnerRemarks />}
    </Modal>
  );
}
export default AppModal;
