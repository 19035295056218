import { SubscriberState } from "./subscriberType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SubscriberState = {
  subscribers: [],
  total: 0,
  loading: false,
};

export const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  reducers: {
    getSubscriber: (state, action) => {
      state.loading = false;
      state.total = action.payload.total;
      state.subscribers = action.payload.subscribers;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
const subscribersReducer = subscribersSlice.reducer;

export const subscribersActions = subscribersSlice.actions;
export default subscribersReducer;
