import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./slices/auth/authSlice";
import careersReducer from "./slices/career/careerSlice";
import contactsReducer from "./slices/contact/contactSlice";
import jobsReducer from "./slices/job/jobSlice";
import modalReducer from "./slices/modal/modalReducer";
import partnersReducer from "./slices/partner/partnerSlice";
import subscribersReducer from "./slices/subscriber/subscriberSlice";

const rootReducer = combineReducers({
  form: formReducer,

  modal: modalReducer,
  user: authReducer,
  jobs: jobsReducer,
  careers: careersReducer,
  contacts: contactsReducer,
  partners: partnersReducer,
  subscribers: subscribersReducer,
});

export default rootReducer;
