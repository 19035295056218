import { CareerState } from "./careerType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CareerState = {
  careers: [],
  loading: false,
};

export const careerSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    getCareers: (state, action) => {
      state.loading = false;
      state.careers = action.payload;
    },
    addRemarks: (state, action) => {
      state.loading = false;
      let index = state.careers.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.careers[index] = action.payload;
    },
    careerStatus: (state, action) => {
      console.log("Career payload", action.payload);
      state.loading = false;
      let index = state.careers.findIndex(
        (row: any) => row._id === action.payload._id
      );
      state.careers[index] = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
const careersReducer = careerSlice.reducer;

export const careersActions = careerSlice.actions;
export default careersReducer;
