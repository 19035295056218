import useEffectOnce from "hooks/useEffectOnce";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateForm({ index }: any) {
  const dispatch = useAppDispatch();
  const { partners } = useAppSelector((state) => state.partners);
  useEffectOnce(() => {
    let data;

    if (index >= 0) data = partners[index];
    const { remarks } = data;
    dispatch(change("RemarksForm", "remarks", remarks));
  });

  return null;
}
