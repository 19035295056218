import FieldError from "components/atoms/FieldError";
import FormControl from "components/atoms/FormControl";
import ReduxTextarea from "components/molecules/TextareaRedux/ReduxTextarea";

export default function FormControlTextareaRedux({
  input,
  label,
  handleBlur,
  handleFocus,
  meta: { error, touched },
  ...rest
}: any) {
  const inputProps = {
    input,
    handleBlur,
    handleFocus,
    meta: { error, touched },
    ...rest,
  };

  return (
    <FormControl
      label={label}
      caption={touched && error && <FieldError error={error} />}
    >
      <ReduxTextarea {...inputProps} />
    </FormControl>
  );
}
