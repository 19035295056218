export const ADD_JOB = "ADD_JOB";
export const JOB_STATUS = "JOB_STATUS";
export const ADD_REMARKS = "ADD_REMARKS";
export const CAREER_STATUS = "CAREER_STATUS";
export const CONTACT_STATUS = "CONTACT_STATUS";
export const ADD_CONTACT_REMARKS = "ADD_CONTACT_REMARKS";
export const PARTNER_STATUS = "PARTNER_STATUS";
export const PARTNER_REMARKS = "PARTNER_REMARKS";

export interface ModalState {
  isOpen: boolean;
  type: string;
  size: string;
  data: any;
}

interface Action {
  type: string;
  size: string;
  payload: boolean;
  data: any;
}

export type ModalAction = Action;
