import { Cell, Grid } from "baseui/layout-grid";
import {
  StyledRoot,
  StyledTable,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableHead,
  StyledTableBody,
} from "baseui/table-semantic";
import TableLoader from "components/atoms/TableLoader";
import moment from "moment";
import { useAppSelector } from "redux/hooks";
import Pagination from "./Pagination";
import "./Subscriber.css";

const Subscriber = () => {
  const { subscribers, loading } = useAppSelector((state) => state.subscribers);

  return (
    <div className="dashboard">
      <Grid>
        <Cell span={[4, 4, 12]}>
          <h2>Subscribers</h2>
        </Cell>
        <Cell span={[4, 4, 12]}>
          <StyledRoot style={{ backgroundColor: "transparent" }}>
            <StyledTable style={{ borderSpacing: "0 12px" }}>
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Email</b>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell style={{ borderBottomWidth: 0 }}>
                    <b>Date</b>
                  </StyledTableHeadCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {!loading &&
                  subscribers?.map((row: any, index: number) => (
                    <StyledTableBodyRow
                      key={index}
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {row.email}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                          borderBottomWidth: 0,
                        }}
                      >
                        {moment(row.createdAt).format("DD.MM.YYYY")}
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
                {loading &&
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <StyledTableBodyRow key={index}>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell style={{ verticalAlign: "middle" }}>
                        <TableLoader rows={1} />
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))}
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </Cell>
        <Cell skip={[0, 0, 6]} span={[4, 4, 6]}>
          <Pagination />
        </Cell>
      </Grid>
    </div>
  );
};

export default Subscriber;
