import FormControlInputRedux from "components/organisms/FormControlInputRedux";
import useEffectOnce from "hooks/useEffectOnce";
import { useRef } from "react";
import { change } from "redux-form";
import { useAppDispatch } from "redux/hooks";
import { ImLocation2 } from "react-icons/im";

const Autocomplete = (props: any) => {
  const inputEl = useRef<any>(null);
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    const initAutoComplete = () => {
      const autocomplete = new (window as any).google.maps.places.Autocomplete(
        inputEl.current
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        props.input.onChange(place.formatted_address);

        for (var i = 0; i < place.address_components.length; i += 1) {
          var addressObj = place.address_components[i];
          for (var j = 0; j < addressObj.types.length; j += 1) {
            if (addressObj.types[j] === "country") {
              dispatch(change("JobForm", "country", addressObj.long_name));
            }
            if (addressObj.types[j] === "locality") {
              dispatch(change("JobForm", "city", addressObj.long_name));
            }
          }
        }
      });
    };
    initAutoComplete();
  });
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <FormControlInputRedux
        startEnhancer={<ImLocation2 size="18px" />}
        inputRef={inputEl}
        {...props}
      />
    </div>
  );
};
export default Autocomplete;
