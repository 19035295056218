import { Input as BaseInput, SIZE } from "baseui/input";

type InputProps = React.ComponentProps<typeof BaseInput>;

const getInputFontStyle = ({ $theme }: { $theme: any }) => {
  return {
    color: $theme.colors.textDark,
    ...$theme.typography.fontBold16,
  };
};
export default function Input(props: InputProps) {
  return (
    <BaseInput
      autoComplete="off"
      size={SIZE.compact}
      overrides={{
        Root: {
          style: (prop) => {
            const {
              $disabled,
              $error,
              $isFocused,
              $theme: { colors },
            } = prop;
            const border = $disabled
              ? colors.borderAlt
              : $error
              ? colors.borderError
              : $isFocused
              ? "#a633db"
              : "#cdcdcd";
            return {
              borderLeftColor: border,
              borderRightColor: border,
              borderTopColor: border,
              borderBottomColor: border,
              borderTopWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              backgroundColor: "#ffffff",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            };
          },
        },
        InputContainer: {
          style: {
            backgroundColor: "#ffffff",
          },
        },
        Input: {
          style: ({ $theme, $isFocused }) => {
            const shadow = $isFocused
              ? "inset 0 1px 3px 0 hsl(191deg 6% 87% / 70%)"
              : "none";
            return {
              ...getInputFontStyle({ $theme }),
              backgroundColor: "#ffffff",
              boxShadow: shadow,
            };
          },
        },
        EndEnhancer: {
          style: {
            backgroundColor: "#ffffff",
          },
        },
        StartEnhancer: {
          style: (prop) => {
            const {
              $disabled,
              $isFocused,
              $theme: { colors },
            } = prop;
            const icon_color = $disabled
              ? colors.borderAlt
              : $isFocused
              ? "#f16b2f"
              : "#cdcdcd";
            return {
              color: icon_color,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: "#ffffff",
            };
          },
        },
      }}
      {...props}
    />
  );
}
