import { createSlice } from "@reduxjs/toolkit";
import { ModalState } from "./modalType";

const initialValue: ModalState = {
  isOpen: false,
  type: "",
  size: "",
  data: "",
};

const modalReducer = createSlice({
  name: "modal",
  initialState: initialValue,
  reducers: {
    openModalWithComponent(state, action) {
      state.type = action.payload.type;
      state.size = action.payload.size;
      state.data = action.payload.data;
      state.isOpen = true;
    },
    closeModal(state) {
      state.isOpen = false;
    },
  },
});

export const modalActions = modalReducer.actions;
export default modalReducer.reducer;
