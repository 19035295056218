import { subscribersActions } from "./../redux/slices/subscriber/subscriberSlice";
import { AppDispatch } from "redux/store";
import { environment } from "environment";
import http from "./http";
import Promisable from "./Promisable";

const url = environment.API_END_URL + "/admin";
const SubscriberService = {
  getSubscriber: async (data: any, dispatch: AppDispatch) => {
    dispatch?.(subscribersActions.setLoading(true));

    http.setJWT();

    const { error, success }: any = await Promisable.asPromise(
      http.get(
        `${url}/getSubscribers?pagination=${data.pagination}&page=${data.page}`
      )
    );
    if (success) {
      console.log("Succcess", success);
      dispatch?.(subscribersActions.getSubscriber(success.data.data));
    }
    dispatch?.(subscribersActions.setLoading(false));
    return error;
  },
};

export default SubscriberService;
