import useEffectOnce from "hooks/useEffectOnce";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateForm({ index }: any) {
  const dispatch = useAppDispatch();
  const { jobs } = useAppSelector((state) => state.jobs);
  useEffectOnce(() => {
    let data;

    if (index >= 0) data = jobs[index];
    const {
      job_title,
      rate_of_pay,
      location,
      country,
      city,
      rank,
      description,
    } = data;
    dispatch(change("JobForm", "city", city));
    dispatch(change("JobForm", "rank", rank));
    dispatch(change("JobForm", "country", country));
    dispatch(change("JobForm", "location", location));
    dispatch(change("JobForm", "job_title", job_title));
    dispatch(change("JobForm", "rate_of_pay", rate_of_pay));
    dispatch(change("JobForm", "description", description));
  });

  return null;
}
