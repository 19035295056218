import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import PrivateRoute from "./PrivateRoute";
import Jobs from "pages/Jobs";
import Partner from "pages/Partner";
import Subscriber from "pages/Subscriber";

const NotFound = lazy(() => import("pages/NotFound"));
const Signin = lazy(() => import("pages/Signin"));
const Careers = lazy(() => import("pages/Careers"));
const Contact = lazy(() => import("pages/Contact"));
const Applicants = lazy(() => import("pages/Careers/Applicants"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<CircleLoader />}>
      <Routes>
        <Route
          path="/careers"
          element={
            <PrivateRoute roles={["admin"]}>
              <Careers />
            </PrivateRoute>
          }
        />
        <Route
          path="/careers/:id"
          element={
            <PrivateRoute roles={["admin"]}>
              <Applicants />
            </PrivateRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <PrivateRoute roles={["admin"]}>
              <Jobs />
            </PrivateRoute>
          }
        />

        <Route
          path="/partners"
          element={
            <PrivateRoute roles={["admin"]}>
              <Partner />
            </PrivateRoute>
          }
        />

        <Route
          path="/contacts"
          element={
            <PrivateRoute roles={["admin"]}>
              <Contact />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscribers"
          element={
            <PrivateRoute roles={["admin"]}>
              <Subscriber />
            </PrivateRoute>
          }
        />

        <Route path="/" element={<Signin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
